import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gotoLogIn } from '../../redux/actions/AuthActions'
import { setShowMembershipModal } from '../../redux/designerReducers/designerReducer'
import { getAdsBackgroundImage } from '../../utils/adSelector'
import {
  AdsFallbackImage,
  AdsFallbackText,
  AdvertMobile,
  AdvertMobileButton,
  AdvertMobileContent,
  AdvertMobileSubtitle,
  AdvertMobileTitle,
  AdvertMobileWrapper,
  AdvertSmall
} from './FeedAdvert.styles'
import mobileAdsWrapper from '../../assets/ads/adsBgMobile.png'

export const FeedAdvert = ({ adCode }) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated)
  const haveMembership = useSelector(state => state.profile?.me?.haveMembership)

  const backgroundImage = useMemo(() => getAdsBackgroundImage(), [])

  if (haveMembership) return null

  const openPurchaseSidebar = () => {
    if (!isAuthenticated) {
      dispatch(gotoLogIn())
      return
    }

    dispatch(setShowMembershipModal(true))
  }

  return (
    <>
      <AdvertSmall>
        <AdsFallbackText
          onClick={() => {
            openPurchaseSidebar()
          }}
          target="_blank"
        >
          <AdsFallbackImage src={backgroundImage} alt="" />
        </AdsFallbackText>
      </AdvertSmall>
      <AdvertMobile
        onClick={() => {
          openPurchaseSidebar()
        }}
      >
        <AdvertMobileWrapper src={mobileAdsWrapper} alt="" />
        <AdvertMobileContent>
          <AdvertMobileTitle>
            Try Decormatters Weekly Membership
          </AdvertMobileTitle>
          <AdvertMobileSubtitle>
            Lots of premium features for you
          </AdvertMobileSubtitle>
          <AdvertMobileButton>$9.99/week</AdvertMobileButton>
        </AdvertMobileContent>
      </AdvertMobile>
    </>
  )
}
