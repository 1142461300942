import React from 'react'

export const makeVersatileComponent = componentMap => {
  const VersatileComponent = props => {
    const Component = componentMap[props.version] || componentMap.default

    return <Component {...props} />
  }

  return VersatileComponent
}
