import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
  useCallback
} from 'react'
import { useSelector } from 'react-redux'
import { POST_GRID_GAP, SMALL_POST_WIDTH } from '../../constants/FeedConstants'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useIsTablet } from '../../hooks/useIsTablet'
import {
  chunkFeedForVirtualization,
  getFeedBuildData,
  prepareFeed
} from '../../utils/prepareFeed'
import {
  MasonryGrid,
  PostsGridInnerBlock,
  PostsGridParent,
  AdContainer,
  LoadingIndicatorContainer
} from './Feed.styles'
import { FeedTileResolver } from './FeedTileResolver'
import { useVirtualizer } from '@tanstack/react-virtual'
import { LoadingIndicator } from '../Loading'

import { FeedAdvert } from './FeedAdvert/FeedAdvert'

export const InspirationsContainerV4 = ({
  feed,
  onFetchMore,
  loading,
  end,
  header,
  footer,
  paddingStart = 100,
  paddingBottom = 115,
  shouldShowLoginProposal
}) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated)
  const isMember = useSelector(state => state?.profile?.me?.haveMembership)
  const [feedBuildData, setFeedBuildData] = useState(
    getFeedBuildData(typeof window !== 'undefined' && window.innerWidth)
  )
  const parentRef = useRef()
  const { likes: likesObj } = useSelector(state => state.like)
  const { savedIds } = useSelector(state => state.saveInspiration)
  const preparedFeed = useMemo(
    () => prepareFeed(feed, isAuthenticated),
    [feed, isAuthenticated]
  )

  const proposeMembership = isAuthenticated && !isMember
  const showAds = !isMember

  const chunkedFeed = useMemo(
    () =>
      chunkFeedForVirtualization(preparedFeed, {
        ...feedBuildData,
        proposeMembership,
        showAds
      }),
    [preparedFeed, feedBuildData, proposeMembership, showAds]
  )

  const ob = useRef()

  const lastFeedItem = useCallback(
    node => {
      if (ob.current) ob.current.disconnect()
      ob.current = new IntersectionObserver(
        e => {
          if (e[0].isIntersecting) {
            if (onFetchMore) {
              onFetchMore()
            }
          }
        },
        {
          threshold: 0.1
        }
      )
      if (node) ob.current.observe(node)
    },
    [onFetchMore]
  )

  if (shouldShowLoginProposal && isMobile) {
    const readyFeed = preparedFeed.slice(0, 6)

    return (
      <PostsGridParent
        id="posts-scroll-box"
        paddingBottom={paddingBottom}
        ref={parentRef}
        isAuthenticated={isAuthenticated}
      >
        <PostsGridInnerBlock>
          {header}
          <MasonryGrid>
            {readyFeed.map(post => {
              return (
                <FeedTileResolver
                  key={post.key}
                  tile={post}
                  likesObj={likesObj}
                  savedList={savedIds}
                  adCode={post.adCode}
                />
              )
            })}
          </MasonryGrid>
          {footer}
        </PostsGridInnerBlock>
      </PostsGridParent>
    )
  }

  return (
    <PostsGridParent
      id="posts-scroll-box"
      paddingBottom={paddingBottom}
      ref={parentRef}
      isAuthenticated={isAuthenticated}
      style={{
        width: `100%`,
        overflow: 'auto',
        paddingBottom: paddingBottom
      }}
    >
      <PostsGridInnerBlock
        style={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {header}
        {chunkedFeed.map((virtualRow, index) => {
          if (index === chunkedFeed.length - 2 && footer) {
            return (
              <MasonryGrid key={index}>
                {!shouldShowLoginProposal && footer}
              </MasonryGrid>
            )
          }

          const chunk = virtualRow

          return (
            <Fragment key={index}>
              <MasonryGrid
                static
                reversed={!isAuthenticated && index % 2 === 0}
              >
                {chunk?.tiles.map(post => {
                  return (
                    <FeedTileResolver
                      key={post.key}
                      tile={post}
                      likesObj={likesObj}
                      savedList={savedIds}
                      adCode={post.adCode}
                      refFn={lastFeedItem}
                    />
                  )
                })}
              </MasonryGrid>
              {index < 3 && (
                <AdContainer height={426}>
                  <FeedAdvert index={index} />
                </AdContainer>
              )}
            </Fragment>
          )
        })}
        {loading === 'pending' && isAuthenticated && (
          <LoadingIndicatorContainer>
            <LoadingIndicator loading={true} />
          </LoadingIndicatorContainer>
        )}
        {shouldShowLoginProposal && footer}
      </PostsGridInnerBlock>
    </PostsGridParent>
  )
}
