import Image from 'next/image'
import styled from 'styled-components'
import shoppingBg from '../../assets/seo/shoppingBg.png'
import ratingBgTablet from '../../assets/seo/ratingBgTablet.png'
import iosAppTablet from '../../assets/seo/iosAppTablet.png'
import landingBgImg from '../../assets/images/landingBg.png'
import tabletRatingBg from '../../assets/seo/tabletRatingBg.png'

export const CreatePageWrapper = styled.main`
  background-color: red;
  /*background-image: url(${landingBgImg});*/
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 70px;

  @media (max-width: 600px) {
    padding-top: 24px;
  }
`

export const CreatePageSection = styled.section`
  margin-top: 32px;
  padding-top: 20px;
  border-top: 1px solid #efefef;

  ${props =>
    props.signUpSuggestion &&
    `
    margin-top: 0;
    padding-top: 0;
  `}

  ${props =>
    props.mobileOnly &&
    `
    @media (min-width: 600px) {
      display: none;
    }
  `}
`

export const CreatePageSectionContainer = styled.section`
  display: flex;
  justify-content: center;
  padding: 80px 0;

  @media (min-width: 769px) {
    padding: 180px 0;
  }
`

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
`

export const TitleSectionText = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  margin: 0;
  padding: 0;
`

export const ViewMoreButton = styled.button`
  border-radius: 100px;
  min-width: 90px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #ff5e6d;
  background: white;
  border: 1.5px solid #ff5e6d;

  & img {
    margin-left: 5px;
  }
`

export const BlogPost = styled.div`
  margin-top: 24px;
`

export const BlogPostCoverImage = styled.img`
  width: 100%;
  height: 234px;
  object-fit: cover;
`

export const BlogPostInfo = styled.div`
  padding: 14px;
`

export const BlogPostCategoryTitle = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #8f989f;
`

export const BlogPostTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-top: 12px;
  margin-bottom: 8px;
`

export const BlogPostAuthor = styled.div`
  display: block;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #000000;

  & span {
    color: #ff5e6d;
  }
`

export const RegisterSuggestionContainer = styled.div`
  position: relative;
  margin-top: 38px;

  & img {
    width: 100%;
  }

  & div:last-child {
    margin-bottom: -70px;
    padding-bottom: 30px;
  }

  @media (max-width: 800px) {
    height: 500px;
    margin-bottom: 0;
    margin-top: 0;

    & div:last-child {
      margin-bottom: 0px;
    }
  }
`

export const CommunityPostsWrapper = styled.div`
  padding: 0;
  box-sizing: border-box;

  @media (max-width: 800px) {
    padding: 0;
  }
`

export const AsSeenOnBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 90px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  width: 100%;
  overflow: auto;
  height: 120px;
  border-top: 1px solid #e5e5e5;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 0 14px;
    font-size: 12px;
    line-height: 15px;
    gap: 30px;
    height: 80px;

    & span {
      flex-shrink: 0;
      max-width: 54px;
      width: 54px !important;
      max-height: 40px;

      & img {
        object-fit: contain;
      }
    }

    & span:first-child {
      margin-right: 30px;
      word-wrap: nowrap;
      white-space: nowrap;
    }
  }
`

export const ShoppingSectionContainer = styled(CreatePageSectionContainer)`
  background-color: rgb(245, 239, 237);
  @media (min-width: 1201px) {
    background: white;
  }
`

export const ShoppingSection = styled.div`
  display: flex;
  gap: 58px;
  margin: 0;
  width: 100%;

  @media (min-width: 1201px) {
    width: 1153px;
  }
  /*
 
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    background: #f5efed;
    margin-top: 0;
  }

  @media (max-width: 1024px) {
    padding: 60px 0 27px;
    background: #f5efed;
    background-size: cover;
  }

  @media (min-width: 1728px) {
    padding-left: 222px;
    padding-right: 222px;
    box-sizing: border-box;
  }
  */
`

export const ProductImage = styled.div`
  width: 40vw;
  overflow: hidden;
  @media (max-width: 800px) {
    display: none;
  }
`

export const SeoSectionInfo = styled.div`
  flex: 1 1 auto;
  width: 100%;
  margin: 0 40px;

  & h2 {
    margin: 0;
    margin-bottom: 32px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    @media (min-width: 769px) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  & p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 32px;
  }

  text-align: center;

  @media (min-width: 1201px) {
    text-align: left;
  }
  /*
  @media (min-width: 760px) {
    flex: 0 0 582px;
    width: 582px;
    margin: 0;
  }
  */
`

export const SeoSectionLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin-bottom: 32px;
  /*  @media (max-width: 1200px) {
    align-items: center;
    justify-content: center;
    max-width: 60vw;
  }

  @media (max-width: 1024px) {
    margin: 2vw auto;
  }

  @media (max-width: 800px) {
    max-width: 95vw;
    margin-bottom: 4vw;
  }*/
`

export const SeoLogoCard = styled.div`
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
  width: 138px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  background: #ffffff;
  border-radius: 5px;

  & img {
    max-width: 80%;
  }

  &:nth-last-child(-n + 1) {
    display: none;
  }

  @media (max-width: 744px) {
    width: 32%;

    &:nth-last-child(-n + 1) {
      display: flex;
    }
  }
`

export const GradientButton = styled.button`
  font-size: 18px;

  font-family: Helvetica Neue;
  /*min-width: 15vw;*/
  /*height: 3.45vw;*/
  width: 224px;
  height: 50px;
  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  border-radius: 25px;
  font-weight: 700;
  color: #ffffff;
  border: none;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  }
  /*
  @media (min-width: 1800px) {
    font-size: 1.1vmax;
  }

  @media (max-width: 1200px) {
    min-width: 19vw;
    height: 4.4vw;
  }

  @media (max-width: 1020px) {
    font-size: 14px;
  }

  @media (max-width: 1000px) {
    min-width: 20vw;
    height: 5vw;
    border-radius: 2.9vw;
  }

  @media (max-width: 744px) {
    min-height: 0;
    min-width: 0;
    height: 42px;
    width: 180px;
    border-radius: 37px;
  }

  @media (max-width: 600px) {
    & button {
      font-size: 14px;
    }
  }
  */
`

export const MobileViewMoreSection = styled.div`
  display: none;

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px;
    margin-bottom: 26px;
  }
`

export const ReviewPageSectionContainer = styled(CreatePageSectionContainer)`
  /*background-color: #f5efed;*/
  margin: 0;
  background-color: #fff;
  background-image: url(${tabletRatingBg});
  background-size: cover;

  @media (min-width: 769px) {
    margin: 0;
    padding-top: 58px;
    padding-bottom: 30px;
    background: #f5efed, url(${ratingBgTablet});
    background-size: cover;
  }

  @media (min-width: 1025px) {
    background: none;
    background-color: #f5efed;
  }
  /*
 

  @media (max-width: 1024px) {
    background-color: #fff;
    background-image: url(${tabletRatingBg});
    background-size: cover;
  }
*/
`

export const ReviewsSection = styled.div`
  & h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 150%;
    margin: 40px;
  }

  @media (max-width: 1024px) {
    & h2 {
      font-size: 20px;
      margin: 0 40px;
    }
  }
  /*
  flex-direction: column;

  & h2 {
    font-size: 32px;
    font-weight: 700;
    font-size: 2.35vw;
    line-height: 150%;
    text-align: center;
    color: #000000;
    margin: 0;
  }

  @media (max-width: 1200px) {
    h2 {
      font-size: 2.5vw;
    }
    padding: 4.7vw 0 5vw;
    background: #f5efed, url(${ratingBgTablet});
    background-size: cover;
  }

  @media (max-width: 1024px) {
    margin-top: 0;
    background-color: #fff;
    background-image: url(${tabletRatingBg});
    background-size: cover;

    & h2 {
      font-size: 3.5vw;
      letter-spacing: 0.01em;
    }
  }

  @media (max-width: 800px) {
    h2 {
      font-size: 4vw;
      max-width: 60vw;
    }
  }
  */
`

export const ReviewsFlex = styled.div`
  display: flex;
  gap: 80px;
  /*align-items: center;
  justify-content: center;
  // gap: 80px;
  gap: 6vw;
  // margin-top: 40px;
  margin-top: 2.9vw;
  box-sizing: border-box;

  & img {
    max-width: 35vw;
  }

  @media (max-width: 1200px) {
    align-items: stretch;
    & img {
      max-width: 25vw;
    }
  }

  @media (max-width: 700px) {
    padding: 1vw 5vw 0;
    & img {
      max-width: 40vw;
    }
  }
*/
`

export const AdaptibleIosImage = styled.div`
  content: '';
  background: white url(${iosAppTablet});
  height: auto;
  width: 35vw;
  border-radius: 10px;
  flex: 1;
  background-position: center;
  background-size: cover;

  @media (min-width: 1200px) {
    display: none;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

export const AppStoreReviewBlock = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 40px 0px #9300001a;
  border-radius: 10px;
  // padding: 8px 42px 11px 17px;
  padding: 1vw 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*flex: 1;
  box-sizing: border-box;*/
  margin: 20px;
  & img {
    flex: 1 1 auto;
    width: 10%;
    max-width: 252px;
  }
  overflow: hidden;

  gap: 12px;
  /* margin-bottom: 4px;

  @media (max-width: 800px) {
    min-width: 90vw;
    padding: 8px 42px 11px 17px;
  }

  @media (max-width: 1024px) {
    margin: 0 20px;
  }
  */
`

export const MoreButton = styled.div`
  flex: 0 0 90px;
  background: #ff5e6d;
  border-radius: 5vw;

  font-size: 2.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: 150%;
  color: #ffffff;

  @media (max-width: 800px) {
    width: 20vw;
    height: 7vw;
    font-size: 3vw;
  }

  @media (max-width: 600px) {
    width: 25vw;
    height: 9vw;
    font-size: 4vw;
  }
`

export const ReviewsList = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 20px;
  gap: 1.1vw;
  // max-width: 620px;
  max-width: 520px;
  margin: 0;
  @media (max-width: 1024px) {
    max-width: none;
    /*margin: 0 20px;*/
  }
  /*
  @media (max-width: 800px) {
    max-width: 90vw;
  }

  @media (max-width: 600px) {
    max-width: 95vw;
  }
  */
`

export const TabletOnlyBlock = styled.div`
  display: none;

  @media (max-width: 744px) and (min-width: 1024px) {
    display: block;
  }
`

export const ReviewBlock = styled.div`
  background: #ffffff;
  border-radius: 10px;
  // padding: 16px 19px;
  padding: 0.75vw 1.1vw;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1024px) {
    max-width: none;
    margin: 0 20px;
  }

  & h3 {
    font-weight: 700;
    // font-size: 24px;
    font-size: 1.35vw;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 0;
  }

  & p {
    font-weight: 400;
    // font-size: 13px;
    font-size: 0.85vw;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 0;
  }

  @media (max-width: 1200px) {
    & h3 {
      // font-size: 20px;
      font-size: 1.6vw;
      font-weight: 700;
      // line-height: 20px;
      line-height: 150%;
      letter-spacing: 0.01em;
    }

    & p {
      // font-size: 12px;
      font-size: 1vw;
      font-weight: 400;
      // line-height: 18px;
      line-height: 150%;
      letter-spacing: 0.01em;
    }
  }

  @media (max-width: 800px) {
    padding: 1.5vw 2.5vw;
    filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.08));

    & h3 {
      font-size: 2.75vw;
    }

    & p {
      font-size: 1.7vw;
    }

    &:last-child {
      display: none;
    }
  }

  @media (max-width: 600px) {
    padding: 2.5vw 3.5vw;
    h3 {
      font-size: 3.5vw;
    }

    p {
      font-size: 2.7vw;
    }
  }
`

export const StarsImage = styled.div`
  overflow: hidden;
  // margin-top: 6px;
  margin-top: 0.32vw;
  // margin-bottom: 15px;
  margin-bottom: 0.8vw;
`

export const MobileRatingImg = styled.img`
  width: 100%;
  filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.08));
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-top: 20px;
`

export const ReviewAuthor = styled.span`
  position: absolute;
  // top: 28px;
  top: 1.6vw;
  // right: 15px;
  right: 0.9vw;
  font-weight: 400;
  // font-size: 13px;
  font-size: 0.75vw;
  line-height: 150%;
  text-align: right;
  letter-spacing: 0.01em;
  color: #000000;

  @media (max-width: 800px) {
    top: 1.5vw;
    right: 1.5vw;
    font-size: 1.75vw;
  }

  @media (max-width: 600px) {
    top: 2.5vw;
    right: 2.5vw;
    font-size: 2.75vw;
  }
`

export const DesktopOnlyBlock = styled.div`
  flex: 0 0 407px;
  width: 407px;

  @media (max-width: 1200px) {
    display: none;
  }
`

export const ReviewDesktopOnlyBlock = styled(DesktopOnlyBlock)`
  flex: 0 0 553px;
  width: 553px;

  @media (max-width: 1200px) {
    display: none;
  }
`

export const DesktopAndTabletOnlyBlock = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`

export const MobileOnlyBlock = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`
