import React, { useEffect, useMemo, useRef, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { POST_GRID_GAP, SMALL_POST_WIDTH } from '../../constants/FeedConstants'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useIsTablet } from '../../hooks/useIsTablet'
import {
  chunkFeedForVirtualization,
  getFeedBuildData,
  prepareFeed
} from '../../utils/prepareFeed'
import {
  MasonryGrid,
  PostsGridInnerBlock,
  PostsGridParent,
  AdContainer,
  LoadingIndicatorContainer
} from './Feed.styles'
import { FeedTileResolver } from './FeedTileResolver'
import { useVirtualizer } from '@tanstack/react-virtual'
import { LoadingIndicator } from '../Loading'

import { FeedAdvert } from './FeedAdvert/FeedAdvert'

export const InspirationsContainerV3 = ({
  feed,
  onFetchMore,
  loading,
  end,
  header,
  footer,
  paddingStart = 100,
  paddingBottom = 115,
  shouldShowLoginProposal
}) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated)
  const isMember = useSelector(state => state?.profile?.me?.haveMembership)
  const [feedBuildData, setFeedBuildData] = useState(
    getFeedBuildData(typeof window !== 'undefined' && window.innerWidth)
  )
  const parentRef = useRef()
  const { likes: likesObj } = useSelector(state => state.like)
  const { savedIds } = useSelector(state => state.saveInspiration)
  const preparedFeed = useMemo(
    () => prepareFeed(feed, isAuthenticated),
    [feed, isAuthenticated]
  )

  const proposeMembership = isAuthenticated && !isMember
  const showAds = !isMember

  const chunkedFeed = useMemo(
    () =>
      chunkFeedForVirtualization(preparedFeed, {
        ...feedBuildData,
        proposeMembership,
        showAds
      }),
    [preparedFeed, feedBuildData, proposeMembership, showAds]
  )

  const footerAddition = footer ? 1 : 0
  let feedSize =
    (!end ? chunkedFeed.length + 1 : chunkedFeed.length) + footerAddition
  feedSize = shouldShowLoginProposal && isMobile ? 2 : feedSize

  const rowVirtualizer = useVirtualizer({
    count: feedSize,
    getScrollElement: () => parentRef.current,
    paddingStart,
    paddingEnd: paddingBottom,
    estimateSize: i => {
      const chunk = chunkedFeed[i]

      if (shouldShowLoginProposal && isMobile) return 2000
      if (isTablet && window.innerWidth < 770) return 5400
      if (isMobile) return 2900

      return chunk
        ? chunk.rows * chunk.squareSize +
            chunk.rows * POST_GRID_GAP +
            (i < 3 ? chunk.advertHeight + POST_GRID_GAP : 0)
        : SMALL_POST_WIDTH
    }
  })

  useEffect(() => {
    const windowSizeListener = () => {
      setFeedBuildData(getFeedBuildData(window.innerWidth))
      rowVirtualizer.measure()
    }

    windowSizeListener()

    window.addEventListener('resize', windowSizeListener)

    return () => {
      window.removeEventListener('resize', windowSizeListener)
    }
  }, [rowVirtualizer.measure])

  const virtualItems = rowVirtualizer.getVirtualItems()

  useEffect(() => {
    const [lastItem] = [...virtualItems].reverse()

    if (shouldShowLoginProposal) return

    if (!lastItem || chunkedFeed[lastItem.index]) return

    if (loading !== 'pending' && !end) {
      onFetchMore()
    }
  }, [
    onFetchMore,
    loading,
    end,
    chunkedFeed,
    virtualItems,
    shouldShowLoginProposal
  ])

  if (shouldShowLoginProposal && isMobile) {
    const readyFeed = preparedFeed.slice(0, 6)

    return (
      <PostsGridParent
        id="posts-scroll-box"
        paddingBottom={paddingBottom}
        ref={parentRef}
        isAuthenticated={isAuthenticated}
      >
        <PostsGridInnerBlock>
          {header}
          <MasonryGrid>
            {readyFeed.map(post => {
              return (
                <FeedTileResolver
                  key={post.key}
                  tile={post}
                  likesObj={likesObj}
                  savedList={savedIds}
                  adCode={post.adCode}
                />
              )
            })}
          </MasonryGrid>
          {footer}
        </PostsGridInnerBlock>
      </PostsGridParent>
    )
  }

  return (
    <PostsGridParent
      id="posts-scroll-box"
      paddingBottom={paddingBottom}
      ref={parentRef}
      isAuthenticated={isAuthenticated}
      style={{
        width: `100%`,
        overflow: 'auto',
        paddingBottom: paddingBottom
      }}
    >
      <PostsGridInnerBlock
        height={
          footer
            ? rowVirtualizer.getTotalSize() - 600
            : rowVirtualizer.getTotalSize()
        }
        style={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {header}
        {virtualItems.map(virtualRow => {
          if (virtualRow.index === feedSize - 2 && footer) {
            return (
              <MasonryGrid
                key={virtualRow.index}
                ref={virtualRow.measureRef}
                height={600}
                translateY={virtualRow.start - 600}
              >
                {!shouldShowLoginProposal && footer}
              </MasonryGrid>
            )
          }

          const chunk = chunkedFeed[virtualRow.index]

          if (!chunk)
            return (
              <LoadingIndicatorContainer key={virtualRow.index}>
                <LoadingIndicator loading={true} />
              </LoadingIndicatorContainer>
            )

          return (
            <Fragment key={virtualRow.index}>
              <MasonryGrid
                key={virtualRow.index}
                ref={virtualRow.measureRef}
                reversed={!isAuthenticated && virtualRow.index % 2 === 0}
                translateY={
                  virtualRow.start + (isAuthenticated ? 0 : paddingStart)
                }
              >
                {chunk?.tiles.map(post => {
                  return (
                    <FeedTileResolver
                      key={post.key}
                      tile={post}
                      likesObj={likesObj}
                      savedList={savedIds}
                      adCode={post.adCode}
                    />
                  )
                })}
              </MasonryGrid>
              {virtualRow.index < 3 && (
                <AdContainer
                  height={chunk.advertHeight}
                  translateY={
                    virtualRow.start +
                    (isAuthenticated ? 0 : paddingStart) +
                    10 +
                    chunk.rows * chunk.squareSize +
                    chunk.rows * POST_GRID_GAP
                  }
                >
                  <FeedAdvert index={virtualRow.index} />
                </AdContainer>
              )}
            </Fragment>
          )
        })}
        {shouldShowLoginProposal && footer}
      </PostsGridInnerBlock>
    </PostsGridParent>
  )
}
