export const MEMBERSHIP_ADVERT_FREQUENCY = 14
export const CHALLENGES_ADVERT_FREQUENCY = 11
export const ADS_FREQUENCY = 6
export const BIG_POST_FREQUENCY = 7
export const MEMBERSHIP_ADVERT_TYPE = 'MEMBERSHIP_ADVERT'
export const CHALLENGES_ADVERT_TYPE = 'CHALLENGES_ADVERT'
export const ADS_FEED_TYPE = 'ADS_FEED'
export const REGULAR_POST_TYPE = 'REGULAR_POST'
export const POST_GRID_GAP = 20
export const SMALL_POST_WIDTH = 320 + POST_GRID_GAP
export const SMALL_POST_HEIGHT = 320 + POST_GRID_GAP
export const BIG_POST_WIDTH = SMALL_POST_WIDTH * 2 + POST_GRID_GAP
export const BIG_POST_HEIGHT = SMALL_POST_HEIGHT * 2 + POST_GRID_GAP
export const BANNER_ADVERT_WIDTH = SMALL_POST_WIDTH * 3 + POST_GRID_GAP * 2
export const BANNER_ADVERT_HEIGHT = SMALL_POST_HEIGHT
export const ADS_WIDTH = SMALL_POST_WIDTH
export const ADS_HEIGHT = SMALL_POST_HEIGHT
export const SMALL_POST_COLUMNS = 1
export const BIG_POST_COLUMNS = 2
export const BANNER_ADVERT_COLUMNS = 3
export const ADS_COLUMNS = 1
export const SMALL_POST_ROWS = 1
export const BIG_POST_ROWS = 2
export const BANNER_ADVERT_ROWS = 1
export const ADS_ROWS = 1
