import Link from 'next/link'
import React from 'react'
import { logEvent } from '../../../utils/firebase'
import { Wrapper, Title, Description, Button } from './FeedAdvert.styles'

import ideasBg from '../../../assets/advertIdeas.png'
import gamesBg from '../../../assets/advertGames.png'
import communityBg from '../../../assets/advertCommunity.png'

const contentMap = {
  ideas: {
    title: 'DecorMatters Helps Make Your Home Interior Design Ideas a Reality',
    description:
      'Our interior design community is full of professional and amateur designers. The DecorMatters design app is about more than sharing interior design inspiration. Our community also recommends products, which you can purchase right in the app! With millions of products from over 30 top furniture brands right at your fingertips, you can bring home interior design ideas to life with the click of a button.',
    backgroundImg: ideasBg
  },
  games: {
    title: 'Play Design Games to Fine-Tune Your Interior Home Ideas',
    description:
      "When you download DecorMatters, you'll also have access to tons of daily design games for a chance to win rewards. These challenges are the perfect chance to practice your design skills and share your interior home ideas with the world.",
    backgroundImg: gamesBg
  },
  community: {
    title:
      'Join the DecorMatters Interior Design Community and Find Your Inspiration Today',
    description:
      'The DecorMatters interior design community brings together designers, planners, furniture shoppers and retailers to share their creativity and ideas in one space. Find your interior design inspiration, share your ideas and create your perfect space with DecorMatters today.',
    backgroundImg: communityBg
  }
}

export const FeedAdvert = ({ index }) => {
  const type = {
    0: 'community',
    1: 'ideas',
    2: 'games'
  }[(index + 1) % 3]

  const { title, description, backgroundImg } = contentMap[type]
  return (
    <Wrapper
      style={{
        backgroundImage: `url(${backgroundImg})`
      }}
    >
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Link
        onClick={() => {
          logEvent('create_design_banner_click', {})
        }}
        href="/#challenges"
        passHref
      >
        <Button>Create Design</Button>
      </Link>
    </Wrapper>
  )
}
