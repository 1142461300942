import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import exploreBgJpgImg from '../../assets/explore-bg-membership.jpeg'
import exploreBgWebpImg from '../../assets/explore-bg-membership.webp'
import {
  default as featuredBgImg,
  default as featuredBgWebpImg
} from '../../assets/featured-bg-membership.jpg'
import {
  setShowMembershipModal,
  setShowPurchaseCoins
} from '../../redux/designerReducers/designerReducer'
import {
  MembershipAdvertWrapper,
  MembershipBannerButton,
  MembershipBannerContent,
  MembershipBannerPrice,
  MembershipBgBanner
} from './MembershipAdvert.styles'

const ADVERT_CONTENT = {
  featured: {
    subtitle: 'Become a member',
    title: 'Unlock all premium items and tools',
    backgroundImgWebp: featuredBgWebpImg,
    backgroundImgJpg: featuredBgImg
  },
  explore: {
    subtitle: 'Become a member',
    title: 'Upload your own items to create unique Designs',
    backgroundImgWebp: exploreBgWebpImg,
    backgroundImgJpg: exploreBgJpgImg
  }
}

export const MembershipAdvert = ({ location = 'featured' }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.profile.me)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const { subtitle, title, backgroundImgWebp, backgroundImgJpg } =
    ADVERT_CONTENT[location]

  if (!isAuthenticated || user?.haveMembership || !user) return null

  return (
    <MembershipAdvertWrapper>
      <MembershipBgBanner src={backgroundImgWebp} alt="" />
      <MembershipBannerContent>
        <h3>{subtitle}</h3>
        <h2>{title}</h2>

        <MembershipBannerButton
          onClick={() => dispatch(setShowMembershipModal(true))}
        >
          Learn more
        </MembershipBannerButton>
      </MembershipBannerContent>
      <MembershipBannerPrice>
        Only <strong>$3.33/month</strong>
      </MembershipBannerPrice>
    </MembershipAdvertWrapper>
  )
}
