import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InspirationsContainer } from './index'
import { FEED_RESET } from '../../redux/constants'
import { fetchFeed, setAttr } from '../../redux/feedInfiniteSlice'
import { LoadingIndicator2 } from '../Loading/LoadingIndicator'
import SuggestRegisterScroll from '../register/SuggestRegisterScroll'
import { FeedContainer, InspirationsFeedTitle } from './Feed.styles'

export const InspirationsV3 = ({
  version,
  title,
  showTitle = false,
  filters,
  paddingBottom,
  disableInitialLoad,
  ...inspirationsContainerProps
}) => {
  const dispatch = useDispatch()
  const inspirations = useSelector(state => state.inspiration)
  const searchFeed = useSelector(state => state.search)
  const { isAuthenticated } = useSelector(state => state.auth)

  const feed = filters?.searchWord ? searchFeed : inspirations

  useEffect(() => {
    if (filters.searchWord) {
      dispatch(
        setAttr('search')({
          addition: { text: filters.searchWord }
        })
      )
      dispatch(fetchFeed('search')({ status: FEED_RESET }))
    } else {
      if (!filters) {
        throw new Error('No filters provided to Inspirations component')
      }

      dispatch(setAttr('inspiration')({ addition: filters }))
      dispatch(fetchFeed('inspiration')({ status: FEED_RESET }))
    }
  }, [filters, dispatch, disableInitialLoad])

  const shouldShowLoginProposal = !isAuthenticated && inspirations.page >= 2

  const handleFetchMore = () => {
    if (feed.feed.length === 0) return
    if (shouldShowLoginProposal) return

    if (filters.searchWord) {
      dispatch(fetchFeed('search')({ status: null }))
    } else {
      dispatch(fetchFeed('inspiration')({ status: null }))
    }
  }

  return (
    <FeedContainer>
      <InspirationsContainer
        version={version}
        {...feed}
        onFetchMore={handleFetchMore}
        searchWord={inspirations.feed.searchWord}
        paddingBottom={paddingBottom}
        paddingTop={100}
        header={
          showTitle && title ? (
            <InspirationsFeedTitle>
              {title.length > 100 ? title.substring(0, 100) + '...' : title}
            </InspirationsFeedTitle>
          ) : null
        }
        shouldShowLoginProposal={shouldShowLoginProposal}
        footer={shouldShowLoginProposal ? <SuggestRegisterScroll /> : null}
        {...inspirationsContainerProps}
      />
    </FeedContainer>
  )
}
