import {
  ADS_FEED_TYPE,
  CHALLENGES_ADVERT_TYPE,
  MEMBERSHIP_ADVERT_TYPE,
  REGULAR_POST_TYPE
} from '../../constants/FeedConstants'

import ChallengesAdvert from '../ChallengesAdvert'
import FeedAdvert from '../FeedAdvert'
import MembershipAdvert from '../MembershipAdvert'
import { InspirationItem } from './InspirationItem'

export const FeedTileResolver = ({ adCode, tile, refFn, location }) => {
  switch (tile.tileType) {
    case REGULAR_POST_TYPE:
      return <InspirationItem hideActionButton refFn={refFn} {...tile} />
    case MEMBERSHIP_ADVERT_TYPE:
      return <MembershipAdvert location={location} />
    case ADS_FEED_TYPE:
      return <FeedAdvert adCode={adCode} location={location} />
    default:
      return null
  }
}
