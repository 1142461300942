import styled from 'styled-components'

export const AdvertSmall = styled.div`
  width: 100%;
  height: 100%;
  min-width: 250px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1 / 1;

  @media (max-width: 768px) {
    display: none;
    margin: auto;
    width: 95%;
    margin-top: 50px;
    margin-bottom: 50px;
    aspect-ratio: auto;
  }
`

export const AdvertTagContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  background: #ffffff;
  border: 1px solid #ff5e6d;
  border-radius: 30px;
  padding: 2px 7px 2px 3px;

  & img {
    width: auto;
    height: 19px;
    margin-right: 3px;
  }
`

export const AdvertTag = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: #ff5e6d;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AdsFallbackText = styled.a`
  font-size: 1.5vmax;
  font-weight: bold;
  color: #ff5e6d;
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1;
`

export const AdsFallbackImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${props => (props.hidden ? 0 : 1)};

  @media (max-width: 768px) {
    object-fit: contain;
    height: 95%;
    padding: 5px;
  }
`

export const AdsFrame = styled.div`
  z-index: 2;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  max-width: 95%;
  border: 1px solid #dcdcdc;
`

export const AdsFrameCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${props => (props.hidden ? 0 : 1)};
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${props => (props.hidden ? -1 : 10)};
  transition: opacity 0.3 ease-in-out;
`

export const AdvertMobile = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    display: none;
  }
`

export const AdvertMobileWrapper = styled.img`
  width: 100%;
`

export const AdvertMobileContent = styled.div`
  padding: 24px 16px 0;
`

export const AdvertMobileTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`

export const AdvertMobileSubtitle = styled.span`
  display: block;
  font-size: 14px;
  line-height: 19.2px;
`

export const AdvertMobileButton = styled.button`
  border: none;
  border-radius: 41px;
  background: #ff5e6d;
  width: 100%;
  height: 40px;
  padding: 6px 18px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 19.2px;
  margin-top: 12px;

  &:hover {
    opacity: 0.8;
  }
`
