import { makeVersatileComponent } from '../../utils/makeVersatileComponent'
import { InspirationsContainerV3 } from './InspirationsContainerV3'
import { InspirationsContainerV4 } from './InspirationsContainerV4'
import { InspirationsV3 } from './InspirationsV3'
export { InspirationItem } from './InspirationItem'

export default makeVersatileComponent({
  default: InspirationsV3
})

export const InspirationsContainer = makeVersatileComponent({
  default: InspirationsContainerV3,
  v4: InspirationsContainerV4
})
