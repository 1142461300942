import styled from 'styled-components'

export const MembershipAdvertWrapper = styled.div`
  width: 100%;
  height: 100%;
  grid-column: span 3;
  grid-row: span 1;
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: 1400px) and (max-width: 1590px) {
    grid-row: 3;
    grid-column-start: -4;
    grid-column-end: -1;
  }

  @media (max-width: 1020px) {
    width: 100%;
    height: 100%;
    grid-column-start: 1;
    grid-column-end: -1;
  }

  @media screen and (max-width: 820px) {
    border-radius: 10px;
    overflow: hidden;
    width: auto;
    height: 238px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
    height: 168px;
    border-radius: 0px;
  }
`

export const MembershipBgBanner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`

export const MembershipBannerContent = styled.div`
  color: white;
  z-index: 1;
  max-width: 450px;
  padding-top: 32px;
  padding-left: 50px;

  & h3 {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    margin: 0;
    margin-bottom: 17px;
  }

  & h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    margin-bottom: 4vmax;
  }

  @media screen and (min-width: 1400px) and (max-width: 1600px) {
    & h2 {
      margin-bottom: 2vmax;
    }
  }

  @media screen and (max-width: 820px) {
    & h2 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 20px;
    }

    & h3 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 600px) {
    padding-top: 30px;
    padding-left: 20px;

    & h2 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
      max-width: 241px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    & h3 {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 3px;
    }
  }
`

export const MembershipBannerButton = styled.button`
  background: #ffffff;
  border-radius: 37px;
  width: 210px;
  height: 42px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: black;

  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }

  @media screen and (max-width: 786px) {
    width: 116px;
    height: 36px;
    font-size: 14px;
  }
`

export const MembershipBannerPrice = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff5e6d;
  border-radius: 0px 10px 0px 12px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  padding: 7px 12px;

  @media screen and (max-width: 786px) {
    font-size: 14px;
    display: none;
  }
`
