import chunk from 'lodash/chunk'
import {
  ADS_FEED_TYPE,
  MEMBERSHIP_ADVERT_TYPE,
  REGULAR_POST_TYPE
} from '../constants/FeedConstants'
import { getImageSource } from './getImageSource'

export const prepareFeed = data => {
  const feed = []

  if (!data || data.length === 0) return feed

  data.forEach((item, index) => {
    if (!item?.title?.includes('tutorialpost')) {
      feed.push({
        key: item.objectId,
        index: index,
        tileType: REGULAR_POST_TYPE,
        source: getImageSource(item),
        id: item.objectId,
        user: item.user,
        likes: item.numLikes,
        gifts: item.numGifts,
        type: item.type,
        title: item.title ? item.title : 'Decormatters',
        sourcePost: { ...item }
      })
    }
  })

  return feed
}

export const chunkFeedForVirtualization = (
  feed,
  {
    columns,
    rows,
    postsCount,
    squareSize,
    advertHeight,
    proposeMembership,
    showAds = false,
    shouldShowLoginProposal
  }
) => {
  const chunkSize = postsCount + (showAds ? 0 : 1)

  if (shouldShowLoginProposal) {
    return {
      tiles: feed,
      rows,
      columns,
      postsCount,
      squareSize,
      advertHeight
    }
  }

  return chunk(feed, chunkSize).map((chunk, index) => {
    if (showAds) {
      chunk.splice(5, 0, {
        tileType: ADS_FEED_TYPE,
        key: index + ADS_FEED_TYPE
      })
    }

    if (index >= 3 && proposeMembership) {
      chunk.splice(6, 3, {
        tileType: MEMBERSHIP_ADVERT_TYPE,
        key: index + MEMBERSHIP_ADVERT_TYPE
      })
    }

    return {
      tiles: chunk,
      rows,
      columns,
      postsCount,
      squareSize,
      advertHeight
    }
  })
}

export const getFeedBuildData = (screenWidth = 0) => {
  const vw = screenWidth / 100

  const feedBuildData = {
    columns: 5,
    rows: 3,
    postsCount: 8,
    squareSize: screenWidth * 18,
    advertHeight: 426
  }

  if (screenWidth >= 1700) {
    feedBuildData.squareSize = vw * 16.7
    feedBuildData.advertHeight = 426
  }

  if (screenWidth >= 1590 && screenWidth < 1700) {
    feedBuildData.squareSize = vw * 18
    feedBuildData.advertHeight = 400
  }

  if (screenWidth >= 1400 && screenWidth < 1590) {
    feedBuildData.rows = 3
    feedBuildData.columns = 5
    feedBuildData.postsCount = 8
    feedBuildData.squareSize = vw * 18
    feedBuildData.advertHeight = 350
  }

  if (screenWidth >= 1300 && screenWidth < 1400) {
    feedBuildData.rows = 3
    feedBuildData.columns = 4
    feedBuildData.postsCount = 8
    feedBuildData.squareSize = vw * 23
    feedBuildData.advertHeight = 330
  }

  if (screenWidth < 1300) {
    feedBuildData.rows = 4
    feedBuildData.columns = 3
    feedBuildData.postsCount = 8
    feedBuildData.squareSize = vw * 30
    feedBuildData.advertHeight = 320
  }

  if (screenWidth < 1030) {
    feedBuildData.rows = 4
    feedBuildData.columns = 3
    feedBuildData.postsCount = 8
    feedBuildData.squareSize = vw * 30
    feedBuildData.advertHeight = 300
  }

  if (screenWidth < 800) {
    feedBuildData.advertHeight = 0
  }

  return feedBuildData
}
