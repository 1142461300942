import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  background-size: cover;
  background-position: center;
`

export const Title = styled.h2`
  font-family: Helvetica Neue;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #ffffff;

  max-width: min(770px, 70vw);

  margin-top: 0;
  margin-bottom: 32px;

  text-shadow: 0px 0px 10px #00000040;

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 22px;
    max-width: 60vw;
  }

  @media (max-width: 1000px) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
  }
`

export const Description = styled.p`
  font-family: Helvetica Neue;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 10px #00000040;

  max-width: min(850px, 70vw);

  margin-top: 0;
  margin-bottom: 32px;

  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 22px;
    max-width: 60vw;
  }

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`

export const Button = styled.button`
  height: 50px;
  width: 230px;
  border-radius: 101px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;

  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;

  @media (max-width: 1000px) {
    height: 40px;
    width: 200px;
    font-size: 14px;
  }
`
